var breakpoint_md = '';
var breakpoint_sm = 768;
var breakpoint_xs = '';


$(document).ready(function(){
	$('.slick--default').slick(
		{
			dots: true,
			arrows: false,
			adaptiveHeight: true,
			mobileFirst: true,
			draggable: false,
			swipe: true

		}
	);
	$('.slick--center').slick(
		{
			dots: true,
			arrows: true,
			adaptiveHeight: false,
			mobileFirst: true,
			draggable: false,
			swipe: true,
			centerMode: true,
			centerPadding: "0%",
			responsive: [
				{
					breakpoint: breakpoint_sm,
					settings: {
						centerPadding: "10%",
						centerMode: true
					}
				}
			]

		}
	);

	$('.slick--multiple-items').slick(
		{
			dots: true,
			arrows: true,
			adaptiveHeight: false,
			mobileFirst: true,
			draggable: false,
			swipe: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: breakpoint_sm,
					settings: {
						slidesToShow: 3
					}
				}
			]

		}
	);



	$('.slick--reference').slick({
		dots: true,
		arrows: true,
		swipe: true,
		slidesToShow: 1
	});


	$('.slick--cards').slick({

		dots: false,
		arrows: true,
		swipe: true,
		slidesToShow: 3,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: breakpoint_sm,
				settings: {
					slidesToShow: 1
				}
			}
		]

	});




});



