//global configuration vars
var initMap;
var bars = {
	deferred: {
		__toArray:function(){
			return Object.keys(bars.deferred).map(function (key) {
				if(key!=='__toArray' && key!=='__resolve'){
					return bars.deferred[key];
				}
			});
		},
		__resolve:function(){
			bars.listener.deferred.forEach(function(func){
				if(typeof func === 'function'){
					func();
				}
			});
		}
	},
	formValidation: {
		active:true,
		reCaptcha:{
			use:true,
			sitekey:'6Le5HEYUAAAAADeeGgXVenpUwymo70DxU8pU3yuK',
			element:'#captcha'
		},
		form: '.bars-form--validate',
		errorClass: 'bars-form--error',
		storageKey: 'bars_form_storage',
		ajax: false
	},
	breakPoints: {
		xs: 599,
		sm: 600,
		md: 900,
		lg: 1200,
	},
	gmaps: {
		apiKey: 'AIzaSyBdhZ7MrgJuOLKyxROWBfV7pU8AZixh6Hc',
		map:null,
		lat: 52.304910,
		lng: 8.978394,
		infoWindow: "<p></p>",
		address:{
			street:'Magdeburger Str. 2',
			zip:'32423',
			city:'Minden',
			country:'D'
		}
	},
	listener:{
		resize:[],
		load:[],
		scroll:[],
		ready:[],
		deferred:[]
	}
};

function isMobile(){
	return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) );
}
