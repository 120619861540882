//SMOOTH SCROLL
$('.anchor').addClass('js--anchor');
$('.anchor-responsive').addClass('js--anchor-responsive');
//$('.anchor-menu').addClass('js--anchor-menu');

$('.js--anchor').click(function(event){
	var elAttr = $(this).attr('href');

	//prevent that an non anchor link is blocked
	if(elAttr.indexOf('#') !== -1){
		event.preventDefault();

		$('body,html').animate({
			scrollTop: $(elAttr).offset().top - 200
		}, 1000, 'swing');
	}
});


$('.js--anchor-responsive').click(function(event){
	var elAttr = $(this).attr('href');

	//prevent that an non anchor link is blocked
	if(elAttr.indexOf('#') !== -1){
		event.preventDefault();

			$('body,html').animate({
			scrollTop: $(elAttr).offset().top - 200
		}, 1000, 'swing');
	}
});


$(window).scroll(function(){
	//anchorMenuScroll()
});

/*
function anchorMenuScroll(){

	var anchorMenuHeight = $(".js--anchor-menu").css('height');
	var scroll = $(window).scrollTop();
	var heroHeight = $(".js-bar-hero").height();

	heroHeight = parseInt(heroHeight);

	if(scroll >= heroHeight){
		$(".anchor-menu").addClass("anchor-menu-fixed");
	}
	if(scroll < heroHeight){
		$(".anchor-menu").removeClass("anchor-menu-fixed");
	}
}*/

