/*------------------------------------
			PROJECT JS
------------------------------------*/
function fillRefModal(btn){

	var refCard = btn.closest('.references-card');

	var headline = refCard.find('h3').html();
	var text = refCard.find('.references-card--text').html();
	var adr = refCard.find('.references-card--adr').html();
	var imgs = refCard.find('.references-card--img img');

	var modal = $('#modalReference');

	var slider = modal.find('.slick--reference');

	modal.modal();

	$('.slick--reference').slick('unslick');
	slider.empty();
	$('.modal-content').css('opacity', '0');

	imgs.each(function(){

		var src = $(this).attr('src');
		var img = '<div class="slick-slide-img"><img src="' + src + '" alt="bild" class=""></div>';

		slider.append(img);

	});

	modal.find('.js--modal-ref-headline').empty().html(headline);
	modal.find('.js--modal-ref-text').empty().html(text);
	modal.find('.js--modal-ref-adr').empty().html(adr);

}


function initRefSlider(){


	$('.slick--reference').slick({
		dots: true,
		arrows: true,
		swipe: true,
		slidesToShow: 1,
	});

}


function showNextRefCards(){


	var cardsShown = $('.references-card:not(.hidden)').length;
	var refCards = $('.references-card');

	refCards.eq(cardsShown++).removeClass('hidden');

	if(cardsShown === refCards.length){
		$('.js--btn-show-more').remove();
	}

}

bars.listener.ready.push(function(){

	var cards = $('.references-card').length;

	if (cards < 5){
		$('.js--btn-show-more').remove();
	}

	$('.modal .btn-primary').on('click', function(){

		$('#modalReference').modal('toggle');

	});

	$('.js--btn-show-more').on('click', function(){

		var cardsToShow = 2;
		var delayCounter = 0;

		var delay = 200;

		for(var i = 0; i < cardsToShow; i++){

			setTimeout(showNextRefCards, delayCounter);
			delayCounter += delay;

		}

	});


	$('.js--open-modal').on('click', function(){

		fillRefModal($(this));

	});

	$('#modalReference').on('shown.bs.modal', function () {
		initRefSlider();
		$('.modal-content').css('opacity', '1');
	});



});