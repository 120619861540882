(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
if(bars.formValidation !== undefined){
	bars.formValidation = $.extend(
			require('./includes/formValidation')($),
			bars.formValidation
	);
}else{
	bars.formValidation = require('./includes/formValidation')($);
}
bars.formValidation.init();

//Organisims
//require('./organisms/cards')(jQuery, bars);
//require('./organisms/blogpost')(jQuery, bars);
//require('./organisms/tabsStacked')(jQuery, bars);
require('./organisms/forms')(jQuery, bars);
require('./organisms/cookie')(jQuery, bars);

require('./includes/animatedNumbers')(jQuery, bars);
require('./includes/skrollrAnimations')(jQuery, bars);
require('./includes/svgStorage')(jQuery, bars);
//require('./includes/viewportWidthScrollbarfix')(jQuery, bars);


//gmaps
if(document.getElementById('bar__map') !== null){
	var _gmap = document.getElementById('bar__map');
	initMap = require('./organisms/gmaps')(jQuery, bars, _gmap);
	bars.listener.ready.push(function(){
		$('body').find('script').eq(0).before('<script src="https://maps.googleapis.com/maps/api/js?callback=initMap&key=' + bars.gmaps.apiKey + '" async defer></script>');

		$('#callout').on('shown.bs.collapse', function(){
			google.maps.event.trigger(document.getElementById('bar__map'), 'resize');
			bars.gmaps.map.setCenter(bars.gmaps.map.getCenter());
		});
	});
}
//custom document ready functions
//TODO remove?
bars.listener.ready.push(function(){


	$('#back-to-top').click(function(){
		//$('#back-to-top').tooltip('hide');
		$('body,html').animate({
			scrollTop: 0
		}, 600);
		return false;
	});

	$(".js-btn-alert").click(function(){
		var message = $(this).attr('data-alert-message');
		var type = $(this).attr('data-alert-type');
		toastr[type](message)
		console.log(type);
	});


	// rotating Cards

	$('.js-rotate-btn').on('click', function(){
		var cardId = $(this).attr('data-card');
		$('#' + cardId).toggleClass('flipped');


	});


	//Pagination
	$('#js-pagination-1').find('.pagination').twbsResponsivePagination();

	$('.js--tabs a').click(function(e){
		e.preventDefault()
		$(this).tab('show')
	});

	$('.dropdown-toggle').dropdown();

	if(isMobile()){
		$('body').addClass('is-mobile');
	}

});

//custom scroll functions
//TODO remove?
bars.listener.scroll.push(function(){
	// shrink on scroll
	if($(document).scrollTop() > 50){ //scroll offset
		$('.navbar-custom').addClass('shrink');
		$('.stage-shelf__wrapper').addClass('shrink');

	}else{
		$('.navbar-custom').removeClass('shrink');
		$('.stage-shelf__wrapper').removeClass('shrink');
	}
});


bars.listener.scroll.push(function(){

	var scrollTop = $(this).scrollTop();

	$('.js--paralax').each(function(){

		//TODO CHECK IF IS IN VIEWPORT
		$(this).css('background-position-y', -(scrollTop * 0.15) + 'px');

	});




});


bars.listener.deferred.push(function(){
	$('body').removeClass('loading');
});

bars.listener.load.push(function(){
	if(bars.formValidation.reCaptcha.use){
		//Render captchas
		window._captchas = []
		$(".g-recaptcha").each(function(){
			var obj = $(this);
			var form = obj.closest('form');
			var captcha_id = grecaptcha.render(obj.attr("id"), {
				"sitekey": bars.formValidation.reCaptcha.sitekey,
				"size": "invisible",
				"callback": function(token){
					form.submit()
				}
			});
			_captchas.push({
				id: captcha_id,
				form: form
			})
		});
	}


});

bars.listener.ready.push(function(){
	$('[data-toggle="tooltip"]').tooltip();
});






},{"./includes/animatedNumbers":2,"./includes/formValidation":3,"./includes/skrollrAnimations":4,"./includes/svgStorage":5,"./organisms/cookie":6,"./organisms/forms":7,"./organisms/gmaps":8}],2:[function(require,module,exports){
module.exports = function animateValue($, bars){

	bars.listener.ready.push(function(){

		var statsNumbers = [];
		var statsCounter = 0;
		$('.js--animated-numbers').each(function(){
			statsNumbers.push($(this).text());
			$(this).text(0);
		});

		//Refill and Animate
		function animateNumbers(){
			$('.js--animated-numbers').each(function(){
				var $this = $(this);
				var num = statsNumbers[statsCounter];
				$({Counter: 0}).animate({Counter: num}, {
					duration: 3000,
					easing: 'swing',
					step: function(){
						$this.text(Math.ceil(this.Counter));
					},
					complete: function(){
						$this.text(num);
					}
				});
				statsCounter++;
			});
		}


		bars.listener.scroll.push(function(){

			var stats = $('body').find('.js-bar--stats');



			if (stats.length > 0) {

				stats.each(function(){

					var scrolled = $(document).scrollTop();
					var distanceToTop = $(this).offset().top;

					if (scrolled > distanceToTop - 500){
						if (!$(this).hasClass('stats-animated'))
						{
							$(this).addClass('stats-animated');
							animateNumbers();
						}
					}

				});

			}

		});

	});
};
},{}],3:[function(require,module,exports){
//Für Form Validate
module.exports = function formValidation($){
	var obj = {
		active: true,
		reCaptcha: {
			use: false,
			sitekey: '',
			element: '#captcha'
		},
		form: null,
		formTarget: null,
		errorClass: 'form--error',
		requiredClass: 'js--input-check',
		submitButton: '.js--form-submit',
		submitLabels: [
			'btn--submit',
			'btn--submit--send',
			'btn--submit--sent'
		],
		storageKey: null,
		responseMessages: {
			invalid: 'Fehler: Bitte korrigieren Sie die rot umrandeten Felder',
			done: 'Formular erfolgreich versendet',
			error: 'Beim Verarbeiten Ihrer Teilnahme ist ein Fehler aufgetreten!'
		},
		api: {
			active: false,
			username: null,
			password: null
		},
		validateForm: function(form){
			var _this = this;
			if(form === undefined || form === '' || form === null){
				form = _this.form;
			}
			if(form === null){
				console.error('Form is not set');
			}
			var $form = $(form);

			var name = $form.attr('name');
			if(name === "" || name === undefined){
				function generateRandomName(){
					var text = "";
					var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

					for(var i = 0; i < 10; i++)
						text += possible.charAt(Math.floor(Math.random() * possible.length));

					return 'form-' + text;
				}

				name = generateRandomName();
				while(window[name] !== undefined){
					name = generateRandomName();
				}
				$form.attr('name', name);
			}
			window[name] = $form.validate({
				submitHandler: function(form){
					var _valid = true;
					if(bars.formValidation.reCaptcha.use && bars.formValidation.reCaptcha.sitekey !== '' && grecaptcha !== undefined){
						$(bars.formValidation.reCaptcha.element).attr('data-sitekey', bars.formValidation.reCaptcha.sitekey);
						for(var i = 0; i < _captchas.length; i++) {
							if(form == _captchas[i].form[0] && grecaptcha.getResponse(_captchas[i].id) === "") {
								grecaptcha.execute(_captchas[i].id);
								return false;
							}
						}

					}
					if(_valid){
						$form.find('.js--form-submit').addClass('btn--submit--send');
						return _this.submitRequest(form);
					}
				},
				errorClass: _this.errorClass,
				showErrors: function(a, fields){
					fields.forEach(function(v){
						$(v.element).addClass(_this.errorClass);
					});
				},
				invalidHandler: function(e, v){
					toastr['error'](_this.responseMessages.invalid);
				}
			});

			$form.on("submit", function(e){
				if(bars.formValidation.ajax){
					e.preventDefault();
				}
			});
		},
		submitRequest: function(form){

			$('.'+this.errorClass).removeClass(this.errorClass);

			var formData = this.getFormData(form);
			var _this = this;

			if(this.storageKey !== null){
				window.sessionStorage.setItem(this.storageKey, JSON.stringify(formData));
			}

			var _target = this.formTarget;
			if(this.formTarget === null){
				_target = $(form).attr('action');
			}

			var cb = function(data){
				switch(data.data){
					case "ok":
						toastr['success'](_this.responseMessages.done);
						$(_this.submitButton).removeClass(_this.submitLabels[1]).addClass(_this.submitLabels[2]);
						break;
					default:
						toastr['error'](_this.responseMessages.error);
						$(_this.submitButton).removeClass(_this.submitLabels[1]);
						break;
				}
				//Reset Captchas
				if(bars.formValidation.reCaptcha.use) {
					for(var i = 0; i < _captchas.length; i++) {
						grecaptcha.reset(_captchas[i].id);
					}
				}
			};

			//Ajax Form POST
			return this.sendAjaxRequest(formData, _target, cb);
		},
		sendAjaxRequest: function(formData, target, cb){
			if(bars.formValidation.ajax){
				if($(bars.formValidation.form).find('input[type="file"]').length <= 0){
					var _this = this;
					$.ajax({
						type: 'POST',
						url: target,
						data: JSON.stringify(formData),     //Zu Sendene Daten
						dataType: 'json',   //Format der Antwort
						processData: false,
						contentType: "application/json",
						encode: false,
						beforeSend: function(xhr){
							if(_this.api.active){
								var username = this.api.username,
									password = this.api.password;
								xhr.setRequestHeader("Authorization", "Basic " + btoa(username + ":" + password));
							}
						}
					}).done(cb);
				}else{
					if($('#js--fileUpload').length <= 0){
						var iframe = '<iframe name="js--fileUpload" id="js--fileUpload" src="" frameborder="0" height="0" width="0" style="width:0; height:0; border:0;"></iframe>';
						$(bars.formValidation.form).append(iframe);
						$(bars.formValidation.form).attr('target', 'js--fileUpload');
						var act = $(bars.formValidation.form).attr('action');
						$(bars.formValidation.form).attr('action', act + (act.indexOf('?') > 0 ? '&' : '?') + 'iframe=1');

						window.addEventListener("message", receiveMessage, false);

						function receiveMessage(event){
							cb(event.data);
						}
					}
					return true;
				}
			}else{
				return true;
			}
		},
		getFormData: function(form){
			var data = $(form).serializeArray();

			var returnArray = {};
			for(var i = 0; i < data.length; i++){
				returnArray[data[i]['name']] = data[i]['value'];
			}

			return returnArray;
		},
		load: function(){
			$.validator.addMethod("pattern", function(value, element, param){
				if(this.optional(element)){
					return true;
				}
				if(typeof param === "string"){
					param = new RegExp("^(?:" + param + ")$");
				}
				return param.test(value);
			}, "Invalid format.");

			$.validator.addMethod("cRequired", $.validator.methods.required);
			$.validator.addClassRules(bars.formValidation.requiredClass, {cRequired: true});

		},
		init: function(){
			if(bars.formValidation.active){
				var _this = this;
				bars.listener.load.push(this.load);
				bars.listener.ready.push(function(){
					var _forms = $(bars.formValidation.form);
					for(var i = 0; i < _forms.length; i++) {
						_this.validateForm(_forms.eq(i)[0]);
					}
				});
			}
		}
	};


	return obj;
};
},{}],4:[function(require,module,exports){
//Für Form Validate
module.exports = function skrollrAnimations($,bars){
	if(!isMobile() && $(window).width() >= bars.breakPoints.sm){
		var $js_skrollr = $('.js--skrollr');
		if($js_skrollr.length > 0){
			$js_skrollr.attr('data-emit-events', true);
			$js_skrollr.attr('data-bottom-top', true);
			$js_skrollr.attr('data-top-bottom', true);

			bars.listener.deferred.push(function(){
				skrollr.init({
				cancelable: false,
				keyframe: function(element, name, direction){
					var $element = $(element);
					switch(true){
						case $element.hasClass('js--hide-hero'):
							if($element.hasClass('bar--hero')){
								if(name === 'data100Bottom' || name === 'dataBottom'){
									if(direction === 'up'){
										$('.js-bar-hero').removeClass('hide');
									}
								}
							}else{
								if(name === 'dataTop'){
									if(direction === 'down'){
										$('.js-bar-hero').addClass('hide');
									}else if(direction === 'up'){
										//$('.js-bar-hero').removeClass('hide');
									}
								}

								if(name === 'dataCenter'){
									if(direction === 'down'){
										//$('.js-bar-hero').removeClass('hide');
									}else if(direction === 'up'){
										$('.js-bar-hero').removeClass('hide');
									}
								}
							}

							break;

						case $element.hasClass('js--skrollr'):

							if(name === 'dataBottomTop'){
								if(direction === 'down'){
									$element.addClass('start-animation');
								}else if(direction === 'up' && $element.data('skrollr-repeat') !== 'once'){
									$element.removeClass('start-animation');
								}
							}

							if(name === 'dataTopBottom'){
								if(direction === 'down' && $element.data('skrollr-repeat') !== 'once'){
									$element.removeClass('start-animation');
								}else if(direction === 'up'){
									$element.addClass('start-animation');
								}
							}

							break;
					}


				}
			});
			});
		}
	}
};
},{}],5:[function(require,module,exports){
//Für Form Validate
module.exports = function svgStorage($,bars) {
	var $storage = $('.js--svgstore'),
		totalDefSolved = 0,
		totalDefToSolve = 0;
	if($storage.length <= 0) return false;
	bars.deferred['svgStorage'] = new $.Deferred();

	if($storage.length > 0){
		$storage.each(function(){
			var $this = $(this);
			totalDefToSolve += JSON.parse($this.data('svgs').replace(/'/g, '"')).length;
		});
		$storage.each(function(){
			var $this = $(this);

			$('body').addClass('loading');
			var svgs = JSON.parse($this.data('svgs').replace(/'/g, '"'));
			svgs.forEach(function(url){
				$.ajax({
					url: url,
					dataType: 'html',
					success: function(data){
						var $data = $(data);
						$data.addClass('js--storedsvg');
						$this.append($data);
						totalDefSolved++;
						if(totalDefSolved === totalDefToSolve){
							bars.deferred.svgStorage.resolve();
						}
					},
					error: function(e){
						console.error('Error while loading svg: ' + url);
					}
				});
			});
		});
	}
};
},{}],6:[function(require,module,exports){
module.exports = function cards($,bars){
	bars.listener.ready.push(function() {
		$('.js--cookie-confirm').on('click', function(){

			setDataPrivacyCookie();

		});
	})

	//CookieHinweis
	var privacyPolicyCookie = getCookie("acceptPrivacyPolicy");
	var cookiebanner = document.getElementById("cookiebanner");
	if(privacyPolicyCookie == "yes"){
		cookiebanner.classList.add("hidden-cookie");
	}
	else{
		cookiebanner.classList.remove("hidden-cookie");
	}


	function setDataPrivacyCookie(){
		setCookie("acceptPrivacyPolicy", "yes", 365);
		cookiebanner.classList.add("hidden-cookie");
	}

	function setCookie(cname, cvalue, exdays){
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname){
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for(var i = 0; i < ca.length; i++){
			var c = ca[i];
			while(c.charAt(0) == ' '){
				c = c.substring(1);
			}
			if(c.indexOf(name) == 0){
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

};
},{}],7:[function(require,module,exports){
module.exports = function forms($){
	bars.listener.ready.push(function(){
		// Forms
		//move label
		$('.input-text, .input-select, .input-textarea').on('change', function(){
			var _this = $(this);
			if(_this.val() != ''){
				_this.next().addClass('label-text-placeholder--active');
			}
			else{
				_this.next().removeClass('label-text-placeholder--active');
			}
		});

		$('.js--checkbox-check').change(function(){
			var _this = $(this);

			if(_this.is(":checked")){
				_this.removeClass('bars-form__checkbox--error');
			}
			else{
				_this.addClass('bars-form__checkbox--error');
			}
		});

		$('.label-text-placeholder').each(function(){
			var $this = $(this);
			$this.parent().find('.input-text, .input-select, .input-textarea').each(function(){
				var $_this = $(this);
				if($_this.val() !== null && $_this.val() !== ''){
					$this.addClass('label-text-placeholder--active');
				}
			});
		});
	});
};
},{}],8:[function(require,module,exports){
// GMaps
module.exports = function($, bars,mapEle){
	//TODO BM: POI > mehr Infos anzeigen deaktivieren
	//TODO BM: API-KEY dynamisch laden
	return function(){
		//Marker Position
		var latlng = {lat: bars.gmaps.lat, lng: bars.gmaps.lng};

		//Marker Icon
		var markerIcon = "assets/images/layout/favicon.png";

		//Marker Title
		var markerTitle = ""

		//MapStyling
		var myMapStyle = [
			{
				"stylers": [
					{"saturation": -100},
					{"gamma": 0}
				]
			},
			{
				"featureType": "water", "stylers": [
				{"visibility": "on"},
				{"saturation": 50},
				{"gamma": 0},
				{"hue": "#2c416f"}
			]
			}
		];

		//options
		var options = {
			center: {lat: latlng.lat - 0.0035, lng: latlng.lng},
			zoom: 14,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
			navigationControl: true,
			mapTypeControl: false,
			streetViewControl: false,
			scaleControl: true,
			draggable: true,
			disableDefaultUI: false,
			styles: myMapStyle
		};

		if(mapEle !== null){
			bars.gmaps.map = new google.maps.Map(mapEle, options);
			var map = bars.gmaps.map;
			/*var marker = new google.maps.Marker({
				position: latlng,
				map: map,
				icon: new google.maps.MarkerImage(markerIcon, null, null, null, new google.maps.Size(28, 28)),
				title: markerTitle
			});*/


			var contentString = "<div class='gmap-info-window'>" +
				"<div>" +
				bars.gmaps.infoWindow +
				"</div>" +
				"</div>";
			var _infoWindow = new google.maps.InfoWindow({
				content: contentString
			});
			// use for contact box
			/*marker.addListener('click', function() {
			 _infoWindow.open(map, marker);
			 });*/

			//marker.setMap(map);
			var addr = bars.gmaps.address.street + ','+bars.gmaps.address.country+'-'+bars.gmaps.address.zip;
			//$('.js--gmap-route').attr('href','https://www.google.de/maps?daddr='+addr);



			/**
			 * Custom Google MAps Overlay (HTML als Marker)
			 */
			var overlay;
			USGSOverlay.prototype = new google.maps.OverlayView();

			var bounds = new google.maps.LatLngBounds(latlng, latlng);

			overlay = new USGSOverlay(bounds,map);

			/** @constructor */
			function USGSOverlay(bounds, map) {

				// Initialize all properties.
				this.bounds_ = bounds;
				this.map_ = map;

				// Define a property to hold the image's div. We'll
				// actually create this div upon receipt of the onAdd()
				// method so we'll leave it null for now.
				this.div_ = null;

				// Explicitly call setMap on this overlay.
				this.setMap(map);
			}

			USGSOverlay.prototype.onAdd = function() {

				//Button Markup
				var div = $("<a href=\"https://www.google.de/maps?daddr="+addr+"\" target=\"_blank\" class=\"btn btn-primary  btn--extendable gmap-marker\">" +
					"<i class=\"icon icon--location\"></i>\n" +
					"<span class=\"btn--extendable__label\">Anfahrt öffnen</span>" +
					"</a>")[0];
				div.style.position = 'absolute';

				this.div_ = div;

				// Add the element to the "overlayLayer" pane.
				var panes = this.getPanes();
				panes.overlayMouseTarget.appendChild(div);
			};

			USGSOverlay.prototype.draw = function() {

				// We use the south-west and north-east
				// coordinates of the overlay to peg it to the correct position and size.
				// To do this, we need to retrieve the projection from the overlay.
				var overlayProjection = this.getProjection();

				// Retrieve the south-west and north-east coordinates of this overlay
				// in LatLngs and convert them to pixel coordinates.
				// We'll use these coordinates to resize the div.
				var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
				var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

				// Resize the image's div to fit the indicated dimensions.
				var div = this.div_;
				div.style.left = sw.x + 'px';
				div.style.top = ne.y + 'px';
			};

			// The onRemove() method will be called automatically from the API if
			// we ever set the overlay's map property to 'null'.
			USGSOverlay.prototype.onRemove = function() {
				this.div_.parentNode.removeChild(this.div_);
				this.div_ = null;
			};
		}
	}
};
},{}]},{},[1]);
